
import { defineComponent, onBeforeMount, ref } from 'vue';
import message from 'ant-design-vue/lib/message';
import storeApp from '@/store/storeApp';
import Icone from '@/core/components/UI/Icone.vue';
import GerenciadorAutenticacao from '../gerenciadores/GerenciadorAutenticacao';
import router from '@/router';
import ServicoApp from '@/servicos/ServicoApp';
import { useAppBase } from '../composables/AppBase';

export default defineComponent({
  name: 'AppLayout',
  props: {
    msg: String,
  },
  components: {
    Icone,
  },
  setup() {
    const { apresentarMensagemSucesso } = useAppBase();

    storeApp.mutations.atualizarCarregando(true);
    const servicoApp = new ServicoApp();
    const gerenciadorAutenticacao = new GerenciadorAutenticacao();
    const layoutMobile = ref(false);
    onBeforeMount(async () => {
      if (!gerenciadorAutenticacao.verificaAutenticacao()) {
        router.push({ name: 'Login' });
      }

      storeApp.mutations.atualizarParceiroAutenticado(storeApp.getters.obterParceiroAutenticado(gerenciadorAutenticacao.obtemIdentificadorParceiro()));
      document.documentElement.style.setProperty('--primary', storeApp.state.parceiroAutenticado.corPrimaria);
      document.documentElement.style.setProperty('--primary-light', storeApp.state.parceiroAutenticado.corSecundaria);
      document.documentElement.style.setProperty('--primary-dark', storeApp.state.parceiroAutenticado.corEscura);

      gerenciadorAutenticacao.obtemIdentificadorParceiro();
      if (gerenciadorAutenticacao.atualizarToken()) {
        const retornoAutenticacao = await servicoApp.atualizarToken();
        if (retornoAutenticacao.autenticado) {
          gerenciadorAutenticacao.salvarDadosAutenticacao(retornoAutenticacao.token, retornoAutenticacao.dataExpiracao.toString());
        }
      }
      const configuracaoPlataforma = await servicoApp.obterConfiguracoes();
      storeApp.mutations.atualizarConfiguracaoPlataforma(configuracaoPlataforma);

      const dadosUsuarioAutenticado = await servicoApp.obterDadosUsuarioAutenticado();
      storeApp.mutations.atualizarUsuarioAutenticado(dadosUsuarioAutenticado);
      storeApp.mutations.atualizarCarregando(false);
    });

    async function deslogar() {
      const retornoAutenticacao = await servicoApp.deslogar();
      if (!retornoAutenticacao.autenticado) {
        gerenciadorAutenticacao.removerDadosAutenticacao();
        router.push({ name: 'Login' });
      } else {
        message.warn(retornoAutenticacao.mensagem);
      }
    }

    function acessarMenu(rota: string) {
      router.push({ name: rota });
    }

    async function limparCache() {
      apresentarMensagemSucesso('Limpeza de cache iniciada com sucesso! Em breve todo conteúdo vai estar atualizado.');
      await servicoApp.limparCache();
    }

    return {
      selectedKeys: ref(['1']),
      collapsed: ref(false),
      storeApp,
      current: ['1'],
      acessarMenu,
      deslogar,
      limparCache,
    };
  },
});
